import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import moment from "moment";
import {
  ACTION_TYPE_LABELS,
  ACTION_TYPES,
  MESSAGE_TYPE_LABELS,
  MESSAGE_TYPES,
  MESSAGE_USER_TYPE_LABELS,
  MESSAGE_USER_TYPES,
  MESSAGE_CHANNEL_TYPE
} from "@/view/pages/messages/messages-enums";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  Strike,
  // Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak
  // HorizontalRule,
  // Image
  //  History,
  // Subscript,
  // Superscript
} from "tiptap-vuetify";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import MessagesApiService from "@/core/services/api.service.messages";
import ApiService from "@/core/services/api.service";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";
import { VOUCHER_TYPE_LABELS, VOUCHER_TYPES } from "@/view/pages/voucher/voucher-enums";
import moment from "moment";
import UserApiService from "@/core/services/api.service.user";
import VouchersApiService from "@/core/services/api.service.vouchers";
import ShopApiService from "@/core/services/api.service.shop-api";
import CountryService from "@/core/services/country.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  components: {
    TiptapVuetify,
    ConfirmDialog
  },
  name: "messages-new",
  data() {
    return {
      valid: false,
      isEdit: false,
      selectedImage: null,
      messageUserTypesArr: Object.values(MESSAGE_USER_TYPES),
      messageTypesArr: Object.values(MESSAGE_TYPES),
      templateTypeLabels: { ["Template"]: "Template" },
      templateType: Object.values({ ["Template"]: "Template" }),
      actionTypesArr: Object.values(ACTION_TYPES),
      channelEnum: MESSAGE_CHANNEL_TYPE,
      userInGroup: Object.values({ null: "ALL USERS", YES: "YES", NO: "NO" }),
      yesNoLabels: {
        ["ALL USERS"]: "All users, not depend",
        ["YES"]: "Yes, user in group",
        ["NO"]: "No, user not in group"
      },
      messageTypes: MESSAGE_TYPES,
      actionTypes: ACTION_TYPES,
      step: 1,
      stepperData: ["Filter", "Preview filter", "Message"],
      message: {
        type: null,
        userRecipientType: null,
        registrationDateFrom: null,
        registrationDateTo: null,
        countryId: null,
        cityIds: [],
        isInGroup: null,
        groupFormationDateFrom: null,
        groupFormationDateTo: null,
        groupIds: [],
        shopIds: [],
        numberOfParticipants: null,
        title: "",
        content: "",
        coverPhoto: "",
        clickToAction: "",
        destination: "",
        actionType: null,
        scheduleMessageTime: null,
        templateType: null,
        programName: ""
      },
      isTemplate: false,
      messageUserLabels: MESSAGE_USER_TYPE_LABELS,
      messageTypeLabels: MESSAGE_TYPE_LABELS,
      actionTypesLabels: ACTION_TYPE_LABELS,
      extensions: [
        //  History,
        Bold,
        Italic,
        Underline,
        Strike,
        OrderedList,
        BulletList,
        ListItem,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Paragraph,
        //  Subscript,
        // Superscript,
        //HorizontalRule,
        Link,
        HardBreak,
        // Image,
        Blockquote
      ],
      countries: [],
      categories: [],
      shopCategories: [],
      selectedCountry: undefined,
      selectedCategory: undefined,
      selectedShopCategory: undefined,
      countriesLoading: false,
      categoriesLoading: false,
      shopCategoriesLoading: false,
      cities: [],
      loading: false,
      groupInput: "",
      groupNumbers: [],
      shopInput: "",
      uploadedImage: "",
      requiredRule: [v => !!v || "Field is required"],
      groups: [],
      shopsAreLoading: false,
      shops: [],
      searchShops: "",
      filteredRecipients: [],
      selectedRecipients: [],
      selectedRecipientsIds: [],
      userIds: [],
      users: [],
      filterValidation: true,
      messageVoucher: false,
      scheduleMessage: false,
      voucherTypesArr: Object.values(VOUCHER_TYPES),
      voucherLabels: VOUCHER_TYPE_LABELS,
      voucherFilters: {
        country: null,
        cities: [],
        shopList: [],
        shopListLoading: false
      },
      voucher: {
        description: null,
        price: null,
        validUntil: null,
        users: [],
        shops: [],
        type: VOUCHER_TYPES.SHOPPING_VOUCHER,
        onlyForArticleId: {},
        referenceId: null,
        debounceLoad: null,
        expirationDate: null
      },
      shopsToAdd: [],
      addShopsDisable: false,
      shopsTableHeaders: [
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "3%"
        },
        {
          text: "ID",
          value: "shopId",
          sortable: false,
          align: "left"
        },
        {
          text: this.$i18n.t("commonTable.shopName"),
          value: "shopName",
          sortable: false,
          align: "left"
        }
      ],
      isValidForm: false,
      isFormTouched: false,
      selectAll: false,
      inputPlaceholder: "Link on the button *"
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await SchedulerApiService.init();
    await UserApiService.init();
    await ShopApiService.init();
    await VouchersApiService.init();
    await this.getCountries();
    await this.getCategories();
    await this.getShopCategories();
    await this.getGroups();
    this.getShops();
    this.getVoucherShops();

    //SET SELECTED COUNTRY
    this.isEdit = !!this.$route.params?.messageData;
    const formData = this.$route.params?.messageData || {};

    await this.getCities();
    await this.setForm(formData);
    if (this.actionTypesArr.length > 0) {
      this.message.actionType = this.actionTypesArr[0];
    }
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.messages");
    },
    isFieldDisabled() {
      return this.message.actionType === this.actionTypes.NONE;
    },
    conditionalRules() {
      return this.isFieldDisabled ? [] : this.requiredRule;
    },
    deeplinkDisabled() {
      return this.message.actionType === this.actionTypes.DEEPLINK;
    },
    deeplinkRules() {
      return this.isFieldDisabled ? [] : this.requiredRule;
    }
  },
  methods: {
    async sendMsgDialog() {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          "Are you sure you want to send this message?"
        )
      ) {
        await this.validateAndSend();
      }
    },
    async cancelMsgDialog() {
      if (
        await this.$refs.confirm.open(
          "Cancel Confirmation",
          "Are you sure you want to cancel? Any unsaved changes will be lost."
        )
      ) {
        this.previousState();
      }
    },
    setForm(data) {
      this.message = {
        type: data?.receiverType || MESSAGE_TYPES.USER,
        userRecipientType: MESSAGE_USER_TYPES.ALL,
        registrationDateFrom: null,
        registrationDateTo: null,
        countryId: null,
        cityIds: [],
        isInGroup: null,
        groupFormationDateFrom: null,
        groupFormationDateTo: null,
        groupIds: [],
        shopIds: [],
        numberOfParticipants: null,
        title: data?.message?.title || "",
        content: data?.message?.content || "",
        coverPhoto: data?.message?.coverPhoto || "",
        clickToAction: data?.message?.clickToAction || "",
        destination: data?.message?.destination || "",
        actionType: data?.message?.actionType || null,
        channel: data?.channel || this.channelEnum.IN_APP,
        programName: data?.programName || null
      };

      this.selectedImage = this.message.coverPhoto;
      this.uploadedImage = this.message.coverPhoto;
      if (this.selectedImage) {
        this.$refs.imageContainer.style.backgroundImage = `url(${this.selectedImage})`;
      }
    },
    resetForm() {
      this.message = {
        type: null,
        userRecipientType: null,
        registrationDateFrom: null,
        registrationDateTo: null,
        countryId: null,
        cityIds: null,
        isInGroup: null,
        groupFormationDateFrom: null,
        groupFormationDateTo: null,
        groupIds: null,
        shopIds: null,
        numberOfParticipants: null,
        title: null,
        content: null,
        coverPhoto: null,
        clickToAction: null,
        destination: null,
        actionType: null
      };
      this.selectedCountry = null;

      //RESET DATES
      this.$refs.registrationDateFrom.clearHandler();
      this.$refs.registrationDateTo.clearHandler();
      this.$refs.groupFormationDateFrom.clearHandler();
      this.$refs.groupFormationDateTo.clearHandler();

      //RESET IMAGE
      if (this.$refs.fileInput) {
        this.$refs.fileInput.$el.querySelector("input[type=file]").value = "";
        this.uploadedImage = "";
        this.selectedImage = null;
        this.$refs.imageContainer.style.backgroundImage = "none";
      }
      /*      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });*/
    },
    async updatePhoto() {
      const fileInput = this.$refs.fileInput.$el.querySelector("input[type=file]");
      const image = fileInput.files[0];

      if (!this.uploadedImage) {
        if (!image) return;
        try {
          const response = await SchedulerApiService.uploadImage(image);
          this.uploadedImage = response.data;
        } catch (error) {
          this.$log.error("Error: ", error);
          this.errored = true;
        }

        const file = fileInput.files[0];

        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;

          // postavljanje slike na bg
          this.$refs.imageContainer.style.backgroundImage = `url(${this.selectedImage})`;
        };

        reader.readAsDataURL(file);
      } else {
        this.onRemoveImage();
      }
    },
    onChangeActioType() {
      if (this.message.actionType === this.actionTypes.POST) {
        this.inputPlaceholder = "Post ID";
      }

      if (this.message.actionType === this.actionTypes.PRODUCT) {
        this.inputPlaceholder = "Product ID";
      }

      if (this.message.actionType === this.actionTypes.SHOP) {
        this.inputPlaceholder = "Shop ID";
      }

      if (this.message.actionType === this.actionTypes.SPECIAL_CATEGORY) {
        this.inputPlaceholder = "Category ID";
      }

      if (this.message.actionType === this.actionTypes.DEEPLINK) {
        this.inputPlaceholder = "Link on the button *";
      }
    },
    onMessageTypeChange() {
      if (this.message.type === "GROUP") {
        this.message.shopIds = null;
        this.message.isInGroup = null;
        this.message.userRecipientType = null;
      } else if (this.message.type === "SHOP") {
        this.message.isInGroup = null;
        this.message.userRecipientType = null;
        this.message.numberOfParticipants = null;
        this.message.groupIds = null;
      } else {
        this.message.numberOfParticipants = null;
        this.message.shopIds = null;
      }
    },
    previousState() {
      this.$router.go(-1);
    },
    async validateAndSend() {
      const isValidForm = this.$refs.form.validate();
      const contentExists = this.message.content;

      if (isValidForm && contentExists) {
        this.sendMessage();
      }
    },

    sendMessage() {
      if ((this.message.shopIds && this.message.shopIds) || null) {
        this.message.shopIds = this.message.shopIds.map(shop => shop.id);
      }

      const message = {
        title: this.message.title || null,
        content: this.message.content || null,
        coverPhoto: this.uploadedImage || null,
        clickToAction: this.message.clickToAction || null,
        destination: this.message.destination || null,
        actionType: this.message.actionType || null
      };

      const type = this.message.type;
      const recipientIds = this.selectedRecipientsIds;
      const cityIds = this.message.cityIds || [];
      const channel = this.message.channel;
      const programName = this.message.programName;
      const isTemplate = this.isTemplate;

      const scheduledAt =
        this.scheduleMessage && this.message.scheduleMessageTime
          ? this.formatToUtc(this.message.scheduleMessageTime)
          : null;

      const body = {
        message,
        type,
        recipientIds,
        cityIds,
        channel,
        scheduledAt,
        programName,
        isTemplate
      };

      MessagesApiService.createMessage(body)
        .then(response => {
          this.$log.debug("Message sent: " + response);

          this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
            title: this.$i18n.t("menu.messages"),
            solid: true,
            variant: "success",
            autoHideDelay: 1000
          });

          setTimeout(() => {
            this.previousState();
          }, 1500);

          if (this.messageVoucher) {
            this.voucher.users = this.users;
            VouchersApiService.post(``, this.voucher)
              .then(response => {
                this.$log.debug("Voucher created: " + response);

                this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
                  title: this.$i18n.t("menu.vouchers"),
                  solid: true,
                  variant: "success",
                  autoHideDelay: 1000
                });
              })
              .catch(error => {
                this.$log.error("Error: ", error);
                this.$bvToast.toast("Error: " + error, {
                  title: this.$i18n.t("menu.vouchers"),
                  solid: true,
                  variant: "error",
                  autoHideDelay: 2000
                });
                this.errored = true;
              });
          }
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
          this.$bvToast.toast("Error: " + error, {
            title: this.$i18n.t("menu.messages"),
            solid: true,
            variant: "error",
            autoHideDelay: 2000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async validateAndFilterRecipients() {
      this.loading = true;
      this.filterRecipients();
    },
    filterRecipients() {
      /*   let shopIds = null;
      if (this.message.shopIds) {
        shopIds = this.message.shopIds.map(shop => shop.id);
      }*/
      let isInGroup = null;
      if (this.message.type !== "GROUP") {
        if (this.message.isInGroup === "NO") {
          isInGroup = false;
          this.message.groupIds = null;
        } else if (this.message.isInGroup === "YES") {
          isInGroup = true;
        } else {
          isInGroup = null;
          this.message.groupIds = null;
        }
      }
      const regStart = this.formatToUtc(this.message.registrationDateFrom);
      const regEnd = this.formatToUtc(this.message.registrationDateTo);

      if (!this.message.type || !this.selectedCountry) {
        this.filterValidation = false;
      } else {
        // all selected to be sent as null
        const cityIds =
          this.message.cityIds?.length !== this.cities.length ? this.message.cityIds : null;
        const groupIds =
          this.message.groupIds?.length !== this.groups.length ? this.message.groupIds : null;
        const shopIds =
          this.message.shopIds?.length !== this.shops.length ? this.message.shopIds : null;

        const filter = {
          type: this.message.type || null,
          userCategoryId: this.selectedCategory?.id || null,
          shopTierId: this.selectedShopCategory?.id || null,
          registrationDateFrom: (regStart === "Invalid date" ? null : regStart) || null,
          registrationDateTo: (regEnd === "Invalid date" ? null : regEnd) || null,
          countryId: this.selectedCountry?.id || null,
          cityIds: cityIds,
          inGroup: isInGroup,
          groupFormationDateFrom: this.message.groupFormationDateFrom || null,
          groupFormationDateTo: this.message.groupFormationDateTo || null,
          groupIds: groupIds,
          shopIds: shopIds,
          //   shopIds: this.message.shopIds || null,
          numberOfParticipants: this.message.numberOfParticipants || null
        };

        this.filterValidation = true;

        this.nextStep();

        MessagesApiService.filterRecipients(filter)
          .then(response => {
            this.filteredRecipients = response.data.recipientsInfo;
            this.selectedRecipients = this.filteredRecipients;

            //   this.voucher.users = this.selectedRecipients;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onCityChange() {
      if (this.message.type === this.messageTypes.SHOP) {
        this.getShops();
      }
    },
    handleSelectAllCities(event) {
      if (event) {
        const ids = this.cities.map(item => item.id) || [];
        this.message.cityIds = ids;
      } else {
        this.message.cityIds = [];
      }

      if (this.message.type === this.messageTypes.SHOP) {
        this.getShops();
      }
    },
    handleSelectAllGroups(event) {
      if (event) {
        const ids = this.groups.map(item => item.id) || [];
        this.message.groupIds = ids;
      } else {
        this.message.groupIds = [];
      }
    },
    handleSelectAllShops(event) {
      if (event) {
        const ids = this.shops.map(item => item.id) || [];
        this.message.shopIds = ids;
      } else {
        this.message.shopIds = [];
      }
    },
    getOwnerUsername(group) {
      if (group.participants && group.participants.length > 0) {
        const ownerParticipant = group.participants.find(
          participant => participant.profileId === group.ownerId
        );
        return ownerParticipant ? ownerParticipant.username : "";
      }
      return "";
    },
    getCities() {
      if (!this.selectedCountry?.id) return;
      this.loading = true;

      return SchedulerApiService.getCities({
        countryId: this.selectedCountry?.id
      })
        .then(res => {
          this.cities = res.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];

          if (!this.selectedCountry) {
            const countryId = CountryService.getActiveCountry();
            this.selectedCountry = this.countries.find(country => country.id === countryId);
            this.onCountryChange();
          }
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    onCountryChange() {
      this.getCities();

      if (this.message.type === this.messageTypes.SHOP) {
        this.getShops();
      }
    },
    getCategories() {
      this.categoriesLoading = true;
      return UserApiService.getCategories()
        .then(res => {
          this.categories = res.data || [];
        })
        .finally(() => {
          this.categoriesLoading = false;
        });
    },
    getShopCategories() {
      this.shopCategoriesLoading = true;
      return ShopApiService.getCategories()
        .then(res => {
          this.shopCategories = res.data || [];
        })
        .finally(() => {
          this.shopCategoriesLoading = false;
        });
    },
    addShopId() {
      const shopId = parseInt(this.shopInput);
      if (!isNaN(shopId)) {
        this.message.shopIds.push(shopId);
        this.shopInput = "";
        console.log(this.message.shopIds);
      }
    },
    selectAllShops() {
      // Handle logic when 'Select All' checkbox changes state
      if (this.selectAll) {
        this.message.shopIds = this.shops.filter(shop => !shop.selectAll).map(shop => shop.id);
      } else {
        this.message.shopIds = [];
      }
    },
    onRemoveImage() {
      // this.$refs.fileInput.$el.querySelector("input[type=file]").value = "";
      this.uploadedImage = "";
      this.selectedImage = null;
      this.$refs.imageContainer.style.backgroundImage = "none";
    },
    getGroups() {
      return MessagesApiService.getGroups()
        .then(response => {
          this.groups = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.loading = false;
          this.referrals = [];
        })
        .finally(() => (this.loading = false));
    },
    getShops() {
      if (this.shopsAreLoading) return;

      const countryId = this.selectedCountry?.id;
      const cityIds = this.message.cityIds.join(",");
      const params = {
        countryId: countryId || null,
        cityIds: cityIds || null
      };

      this.shopsAreLoading = true;
      ApiService.query("api/shops/dropdown-list", { params })
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsAreLoading = false));
    },
    getVoucherShops() {
      const countryId = CountryService.getActiveCountry();
      const cityIds = this.voucherFilters.cities.map(c => c.id).join(",");
      const params = {
        countryId: countryId || null,
        cityIds: cityIds || null
      };

      this.voucherFilters.shopListLoading = true;
      ApiService.query("api/shops/dropdown-list", { params })
        .then(res => {
          this.voucherFilters.shopList = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.voucherFilters.shopListLoading = false));
    },
    onVoucherCityChange() {
      this.getVoucherShops();
    },
    nextStep() {
      this.selectedRecipientsIds = this.selectedRecipients.map(recipient => recipient.id);

      this.userIds = this.selectedRecipients.map(recipient => recipient.userId).filter(id => id);
      if (this.messageVoucher) {
        this.users = this.userIds.map(id => {
          const user = this.selectedRecipients.find(obj => obj.userId === id);
          let userName = "";

          if (user?.nickName) {
            userName = user.nickName;
          } else if (user?.email) {
            userName = user.email;
          }

          return {
            userId: user ? user.userId : null,
            userName: userName
          };
        });
      }
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    addShopsToVoucher() {
      this.addShopsDisable = false;
      this.$log.debug("adding: " + this.shopsToAdd.length + " shops to voucher");
      this.shopsToAdd.forEach(shopToAdd => {
        this.$log.debug("id: " + shopToAdd["id"] + ", name: " + shopToAdd["name"]);
        let shop = {
          shopId: shopToAdd["id"],
          shopName: shopToAdd["name"]
        };
        var index = this.voucher.shops.findIndex(x => x.shopId === shop["shopId"]);
        index === -1 ? this.voucher.shops.push(shop) : console.log("object already exists");
        // this.voucher.shops.push.apply(this.voucher.shops, shopToAdd);
      });
      this.shopsToAdd = [];

      this.addShopsDisable = false;
    },
    handleSelectAllVoucherShops(val) {
      this.shopsToAdd = val ? this.voucherFilters.shopList : [];
    },
    async delShopFromVoucher(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want remove shop " + item.shopName + " for this voucher?"
        )
      ) {
        this.deleteShopFromList(item);
      }
    },
    deleteShopFromList(item) {
      this.$log.debug("Shop to be removed: " + item);
      const newArray = this.voucher.shops.filter(obj => item.shopId !== obj.shopId);
      this.voucher.shops = newArray;
      this.$log.debug("new array: " + newArray);
    },
    formatToUtc(date) {
      return date
        ? moment(date)
            .utc()
            .format()
        : null;
    }
  }
};
